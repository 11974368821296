import logo from './assets/logo_lsv_white.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <h1>Agence web Le Soleil Vert</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>Site en construction</p>
    </div>
  );
}

export default App;
